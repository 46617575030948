@charset "UTF-8";
/* Image Buttons (completely seperate button style) */
/* Holds helper mixins (extends compass functionality) */
/* Holds reusable objects for global use */
/* =============================================================================
 *   Navigation
 * ========================================================================== */
/* Tiny Menu */
/* =============================================================================
 *   Box Styles
 * ========================================================================== */
/* =============================================================================
 *   Messages
 * ========================================================================== */
/* Flyout */
/* Overlay */
/* Cutline */
/* Section Titles */
/* =============================================================================
    Branding (webks)
   ========================================================================== */
a[href="http://www.webks.de"]:hover {
  color: #009cff !important; }

a[href="http://www.drowl.de"]:hover {
  color: #82b22c !important; }

/* -- Colors */
.webks-blue {
  color: #009cff; }

.drowl-green {
  color: #82b22c; }

/* -- Maintenance Page */
body.maintenance-page {
  background: url("../../images/gear_bg.png") center bottom no-repeat #eaeaea !important;
  color: #000000;
  height: 100%;
  margin: 0;
  padding: 0; }

body.maintenance-page h1,
body.maintenance-page h2 {
  color: #454545; }

body.maintenance-page #maintenance-branding {
  padding-top: 50px;
  text-align: center; }

body.maintenance-page #maintenance-main-content {
  background-color: rgba(255, 255, 255, 0.7);
  border-color: #009CFF;
  border-image: none;
  border-style: solid none none;
  border-width: 11px 0 0;
  box-shadow: 2px 2px 15px #7D7D7D;
  color: #6F6F6F;
  margin: 45px auto 0;
  max-width: 500px;
  min-height: 150px;
  overflow: hidden;
  padding: 15px 25px;
  text-align: right; }

body.maintenance-page #webks-bottom {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.5);
  color: #6f6f6f;
  bottom: 0;
  padding: 20px 2%;
  position: absolute;
  width: 96%; }

body.maintenance-page #webks-bottom ul.menu {
  text-align: right; }

body.maintenance-page #webks-bottom .label {
  margin-right: 5px; }

body.maintenance-page #webks-bottom a {
  color: #6f6f6f;
  display: inline-block;
  padding: 0; }

body.maintenance-page #webks-bottom a:hover {
  -moz-transition: all 0.1s linear;
  -moz-transform: translate(0.15em, 0); }

body.maintenance-page #webks-contact-block {
  float: right; }

body.maintenance-page #webks-contact-block h2 {
  margin: 0;
  text-align: right;
  font-weight: normal; }

body.maintenance-page #maintenance-site-name {
  margin: 0 auto;
  text-align: right;
  max-width: 500px; }

body.maintenance-page #maintenance-page-title {
  font-size: 1.4em;
  font-weight: normal;
  margin-bottom: 0.25em; }

/* webks + drowl Branding Block */
.block--drowl-brand {
  text-align: center;
  padding-top: 15px; }
  .block--drowl-brand .block-title {
    display: inline-block;
    font-size: 16px; }
  .block--drowl-brand .block-content {
    display: inline-block;
    line-height: 33px; }
    .block--drowl-brand .block-content * {
      vertical-align: middle; }
  .block--drowl-brand:hover {
    color: #82b22c; }
    .block--drowl-brand:hover .developer-brand__logo {
      filter: grayscale(0%);
      opacity: 1.0; }

.developer-brand__logo {
  width: 120px;
  margin-left: 12px;
  filter: grayscale(100%);
  opacity: 0.6;
  transition-property: filter; }

/* =============================================================================
    Modules
   ========================================================================== */
/* Devel Module */
.dev-query {
  background: #eee;
  padding: 30px; }

/* TinyMCE Skin improvements */
.defaultSkin table.mceLayout {
  border: 0 none !important; }
  .defaultSkin table.mceLayout tr.mceFirst td {
    border-top: 0 none !important; }

/* Responsive Tables */
.webks-responsive-table {
  margin-top: 1.5em; }

.webks-responsive-table .row-0 {
  margin-top: 2em; }

.webks-responsive-table dl {
  border-bottom: 1px solid #ccc; }

.webks-responsive-table dt {
  font-weight: bold; }

.webks-responsive-table dd {
  margin-left: 0; }

/* Module fixes */
/* -- IE6 no more */
#ie6nomore {
  height: auto;
  padding-bottom: 5px; }

#ie6nomore-title {
  line-height: 1.1em; }

/*
 * Contextual Links (Fix nested context link regions)
 */
.contextual-links-region .contextual-links-region .contextual-links-wrapper {
  right: 30px; }

.contextual-links-region .views-field-contextual-links .contextual-links-wrapper {
  right: 30px; }

/* =============================================================================
    Scripts CSS
   ========================================================================== */
/* Clickable Rows */
.responsive-views-row-clickable {
  cursor: pointer; }

/* =============================================================================
    Dashboard
   ========================================================================== */
.page-admin-dashboard #page {
  max-width: none !important; }

#webks-dashboard {
  font-family: arial, verdana, sans-serif; }
  #webks-dashboard .panel-pane {
    margin-bottom: 0.75em; }
  #webks-dashboard .pane-title {
    background: #0e4dac;
    color: #fff;
    font-size: 1em;
    padding: 5px;
    margin-top: 0; }
    #webks-dashboard .pane-title a {
      color: #fff; }
  #webks-dashboard .view-filters .views-exposed-widget {
    border: 0 none;
    width: auto;
    padding: 0 5px;
    min-height: none; }
    #webks-dashboard .view-filters .views-exposed-widget.views-submit-button {
      clear: none; }
  #webks-dashboard .region-two-66-33-second .menu li {
    border-bottom: 1px solid #959ba4; }
    #webks-dashboard .region-two-66-33-second .menu li:last-child {
      border-bottom: 0; }
  #webks-dashboard .region-two-66-33-second .node-add-menu li a::before {
    font-family: "iconfont";
    font-weight: normal !important;
    font-style: normal !important;
    margin-right: 4px;
    content: ""; }
  #webks-dashboard .region-two-66-33-second .node-add-menu li a:before {
    color: #bcc826; }
  #webks-dashboard .region-two-66-33-second .panel-pane {
    background: #ebebeb; }
    #webks-dashboard .region-two-66-33-second .panel-pane:not(.pane-menu-block):not(.pane-search) .pane-content {
      padding: 5px; }
  #webks-dashboard .search-form {
    border-top: 3px solid #0e4dac;
    background: #ebebeb;
    padding: 5px; }

/* =============================================================================
    Media Bar
   ========================================================================== */
/* NODE EDIT IMPROVEMENTS - TODO in modul auslagern (bedenken - kein scss dort), styles sollten nur eingeloggt geladen werden */
/* -- Styling insert buttons */
#page .insert-button {
  padding-left: 35px;
  background: url(images/ico_insert.png) no-repeat 9px center #84bf00;
  color: #253600; }

/* -- Trigger Button beyond body field */
.side-panel-trigger {
  margin-top: 10px; }
  .side-panel-trigger .underline {
    display: block; }

/* -- Sidepanel (media bar) */
body.sidepanel-open {
  padding-right: 33%; }

.js .node-form .group-drowl-mediabar.open {
  width: 33%;
  overflow-y: auto;
  padding: 10px; }
  .js .node-form .group-drowl-mediabar.open .side-panel-closer {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.05em;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    background-color: #d02b2b !important;
    border-color: #d02b2b !important;
    text-shadow: 0 1px 1px #dd5b5b;
    color: white !important;
    padding: 12px 12px;
    border-radius: 5px;
    line-height: 18px;
    font-size: 16px;
    display: block;
    padding: 5px 5px 5px 35px;
    position: relative;
    margin-bottom: 5px; }
    .js .node-form .group-drowl-mediabar.open .side-panel-closer:hover {
      background-color: #d53535 !important;
      border-color: #d53535 !important;
      color: white !important; }
    .js .node-form .group-drowl-mediabar.open .side-panel-closer:active, .js .node-form .group-drowl-mediabar.open .side-panel-closer:focus {
      background-color: #c32828 !important;
      border-color: #c32828 !important;
      color: #7b1a1a !important; }
    .js .node-form .group-drowl-mediabar.open .side-panel-closer::before {
      display: inline-block;
      font-family: "iconfont";
      font-style: normal;
      font-weight: normal;
      margin-right: 5px;
      text-align: center;
      content: ""; }
      .js .node-form .group-drowl-mediabar.open .side-panel-closer::before else {
        line-height: 1; }
    .js .node-form .group-drowl-mediabar.open .side-panel-closer:hover {
      text-decoration: none; }

.js .node-form .group-drowl-mediabar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  overflow: visible;
  background: white;
  color: #454545;
  padding: 0;
  z-index: 5000;
  transition-duration: .5s; }
  .js .node-form .group-drowl-mediabar .side-panel-trigger {
    display: block;
    width: 80px;
    line-height: normal;
    background: white;
    position: absolute;
    left: -100px;
    top: 50%;
    text-align: center;
    margin-top: -25px;
    cursor: pointer;
    padding: 50px 10px 10px 10px;
    box-shadow: -5px 0px 3px rgba(0, 0, 0, 0.1);
    font-weight: bold; }
    .js .node-form .group-drowl-mediabar .side-panel-trigger:before {
      background: #007dcc;
      content: " ";
      position: absolute;
      left: 32px;
      top: 15px;
      height: 20px;
      width: 20px; }
    .js .node-form .group-drowl-mediabar .side-panel-trigger:after {
      background: #009CFF;
      content: " ";
      position: absolute;
      left: 42px;
      top: 25px;
      height: 20px;
      width: 20px; }
  .js .node-form .group-drowl-mediabar .side-panel-closer {
    display: none;
    cursor: pointer; }
  .js .node-form .group-drowl-mediabar table {
    border: 0 none; }
    .js .node-form .group-drowl-mediabar table tr {
      border-bottom: 1px solid #E2E2E2;
      color: #454545; }
      .js .node-form .group-drowl-mediabar table tr td {
        vertical-align: top; }
        .js .node-form .group-drowl-mediabar table tr td:last-child {
          width: 30%; }
          .js .node-form .group-drowl-mediabar table tr td:last-child .btn {
            display: block; }
          .js .node-form .group-drowl-mediabar table tr td:last-child .form-submit {
            background-color: #d02b2b !important;
            border-color: #d02b2b !important;
            text-shadow: 0 1px 1px #dd5b5b;
            color: white !important;
            display: block;
            width: 100%;
            margin-bottom: 4px; }
            .js .node-form .group-drowl-mediabar table tr td:last-child .form-submit:hover {
              background-color: #d53535 !important;
              border-color: #d53535 !important;
              color: white !important; }
            .js .node-form .group-drowl-mediabar table tr td:last-child .form-submit:active, .js .node-form .group-drowl-mediabar table tr td:last-child .form-submit:focus {
              background-color: #c32828 !important;
              border-color: #c32828 !important;
              color: #7b1a1a !important; }
      .js .node-form .group-drowl-mediabar table tr.drag {
        background: #fffdea; }
      .js .node-form .group-drowl-mediabar table tr.drag-previous {
        -webkit-animation: dragging-complete 2s 1;
        /* Safari 4+ */
        -moz-animation: dragging-complete 2s 1;
        /* Fx 5+ */
        -o-animation: dragging-complete 2s 1;
        /* Opera 12+ */
        animation: dragging-complete 2s 1;
        /* IE 10+ */ }
    .js .node-form .group-drowl-mediabar table thead th {
      background: #454545;
      color: #fff; }
      .js .node-form .group-drowl-mediabar table thead th a {
        color: #fff; }
  .js .node-form .group-drowl-mediabar table.sticky-header {
    display: none !important; }
  .js .node-form .group-drowl-mediabar .image-widget::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  .js .node-form .group-drowl-mediabar .image-preview {
    width: 60px;
    background: white; }
  .js .node-form .group-drowl-mediabar .field-name-field-titelbild .image-preview {
    width: auto; }
  .js .node-form .group-drowl-mediabar .image-widget-data {
    float: none; }
  .js .node-form .group-drowl-mediabar legend {
    background: transparent;
    color: white;
    font-weight: bold; }
  .js .node-form .group-drowl-mediabar fieldset {
    background: transparent;
    padding: 0;
    margin: 0 0 5px 0;
    border: 0 none;
    box-shadow: none; }
  .js .node-form .group-drowl-mediabar .fieldset-wrapper {
    padding: 0; }
  .js .node-form .group-drowl-mediabar .fieldset-description {
    background: #efefef;
    border: 1px solid white;
    padding: 5px;
    color: black;
    font-style: italic; }
    .js .node-form .group-drowl-mediabar .fieldset-description a {
      color: black; }
  .js .node-form .group-drowl-mediabar .form-text {
    width: 100%; }
  .js .node-form .group-drowl-mediabar .horizontal-tabs {
    border: 0 none; }
    .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list {
      background: white; }
      .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li {
        background: white;
        width: 50%;
        border: 0 none;
        padding: 0; }
        .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li a {
          color: #454545;
          padding: 7px 10px;
          border-bottom: 1px solid #ccc; }
          .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li a:hover {
            background: white; }
          .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li a:before {
            content: " ";
            float: left;
            display: inline-block;
            width: 31px;
            height: 31px;
            background: url(../images/media_sprite.png) no-repeat 0 0 white;
            border: 1px solid #e7e7e7;
            margin-right: 5px; }
        .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li:hover a:before,
        .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li.selected a:before {
          border-color: #a0e34b; }
        .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li:nth-child(odd) a {
          border-right: 1px solid #ccc; }
        .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li.selected strong {
          font-weight: bold; }
        .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li.horizontal-tab-button-0 a:before {
          background-position: -64px 0; }
        .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li.horizontal-tab-button-1 a:before {
          background-position: -97px 0; }
        .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li.horizontal-tab-button-2 a:before {
          background-position: -31px 0; }
        .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list li.horizontal-tab-button-3 a:before {
          background-position: -130px 0; }
      .js .node-form .group-drowl-mediabar .horizontal-tabs .horizontal-tabs-list strong {
        font-weight: normal;
        line-height: 30px;
        vertical-align: middle; }
  .js .node-form .group-drowl-mediabar .field-name-field-attachments {
    background: none;
    padding: 0;
    margin: 0;
    border: 0 none; }
  .js .node-form .group-drowl-mediabar .form-item {
    background: white;
    padding: 10px;
    color: black;
    border-width: 0 0 1px 0;
    border-style: none none solid none;
    border-color: #E2E2E2;
    border-radius: 0; }
  .js .node-form .group-drowl-mediabar .image-widget-data {
    clear: both; }
  .js .node-form .group-drowl-mediabar .sticky-table .form-item,
  .js .node-form .group-drowl-mediabar .image-widget-data .form-item {
    box-shadow: none; }
  .js .node-form .group-drowl-mediabar .insert {
    clear: both;
    border: 1px solid #eee;
    border-radius: 3px; }
    .js .node-form .group-drowl-mediabar .insert::after {
      clear: both;
      content: " ";
      display: table;
      visibility: hidden; }
    .js .node-form .group-drowl-mediabar .insert .insert-style-select {
      width: 130px;
      float: right; }
      .js .node-form .group-drowl-mediabar .insert .insert-style-select label {
        display: none; }
    .js .node-form .group-drowl-mediabar .insert .form-submit {
      float: left;
      background-color: #bcc826 !important;
      border-color: #bcc826 !important;
      color: white !important; }
      .js .node-form .group-drowl-mediabar .insert .form-submit:hover {
        background-color: #c8d528 !important;
        border-color: #c8d528 !important;
        color: white !important; }
      .js .node-form .group-drowl-mediabar .insert .form-submit:active, .js .node-form .group-drowl-mediabar .insert .form-submit:focus {
        background-color: #b0bb24 !important;
        border-color: #b0bb24 !important;
        color: #6b7216 !important; }

/* Media Bar: headroom.js support */
.js .headroom-bar-pinned .node-form .group-drowl-mediabar {
  top: 50px; }
